import { Splide } from "@splidejs/splide";

// JavaScript code for the custom web component
class SectionSlider extends HTMLElement {
    constructor() {
      super();
    }
  
    connectedCallback() {
     new Splide(this, {
        height: "100%",
        perPage    : 1,
        perMove    : 1,
        pagination : false,
        arrowPath  : 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
        arrows     : true,
        classes    : {
          arrows: 'splide__arrows px-16',
        },
        breakpoints: {
          1024: {
            perPage: 1,
            autoHeight : true,
            padding: { right: '4rem' },
            arrows: false,
          },
        }
      }).mount();
    }
  
}
  
  customElements.define('section-slider', SectionSlider);
  